<template lang="">
  <div class="travelagency">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="enterprise_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <!-- <div class="strip">
        <router-link to="">
          <div class="car">
            国内旅行社
          </div>
        </router-link>
        <router-link to="">
          <div class="cars">
            国际旅行社
          </div>
        </router-link>
      </div> -->
      <el-tabs v-model="activeName" type="card" @tab-click="linkHandler">
        <el-tab-pane label="国内旅行社" name="1">
          <transition name="component-fade" mode="out-in">
            <div class="navig-box">
              <ul class="navig-list">
                <li class="navig-item" v-for="item in cxwndata" :key="item.id">
                  <el-row :gutter="26">
                    <el-col :md="6"
                      ><img
                        v-if="item.theLocal"
                        :src="`/jeecg-boot/${item.theLocal}`"
                        class="imgleft"/>
                      <img
                        v-else
                        src="@/assets/image/main/zanwutp.png"
                        alt=""
                        class="imgleft"
                    /></el-col>
                    <el-col :md="18">
                      <div class="navig-info">
                        <router-link
                          :to="{ path: '/traveladata', query: { id: item.id } }"
                        >
                          <h4 class="title">{{ item.theTitle }}</h4>
                          <p class="desc">{{ item.theInfo }}</p>
                          <p class="time">
                            {{ item.createTime.substring(0, 10) }}
                            <i class="fa fa-long-arrow-right arrow lr"></i>
                          </p>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </li>
              </ul>
              <Pagi
                :total="pagi.total"
                :current="pagi.current"
                :sizes="pagi.size"
                @pagicurrent="togglePagi"
              />
            </div>
          </transition>
        </el-tab-pane>
        <el-tab-pane label="国际旅行社" name="2">
          <transition name="component-fade" mode="out-in">
            <div class="navig-box">
              <ul class="navig-list">
                <li class="navig-item" v-for="item in cxmrdata" :key="item.id">
                  <el-row :gutter="26">
                    <el-col :md="6"
                      ><img
                        :src="`/jeecg-boot/${item.theLocal}`"
                        class="imgleft"
                    /></el-col>
                    <el-col :md="18">
                      <div class="navig-info">
                        <router-link
                          :to="{ path: '/traveladata', query: { id: item.id } }"
                        >
                          <h4 class="title">{{ item.theTitle }}</h4>
                          <p class="desc">{{ item.theInfo }}</p>
                          <p class="time">
                            {{ item.createTime.substring(0, 10) }}
                            <i class="fa fa-long-arrow-right arrow lr"></i>
                          </p>
                        </router-link>
                      </div>
                    </el-col>
                  </el-row>
                </li>
              </ul>
              <Pagi
                :total="pagi.total"
                :current="pagi.current"
                :sizes="pagi.size"
                @pagicurrent="togglePagi"
              />
            </div>
          </transition>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import OnerowLists from "@/components/main/OnerowLists";
import BannerImage from "@/components/main/BannerImage";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  ponents: {},
  created() {
    this.culChange();
  },
  components: {
    Breadcrumb,
    Header,
    Footer,
    OnerowLists,
    BannerImage,
    Pagi,
    Headers,
  },
  data() {
    return {
      activeName: "1",
      result: [],
      // 国内旅行社
      cxwndata: [],
      // 国际旅行社
      cxmrdata: [],

      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      crumbdata: [
        { name: "旅游企业", url: "" },
        { name: "旅行社", url: "/travelagency" },
      ],
    };
  },
  methods: {
    typeChange(type) {
      let params = {
        theType: type,
      };
      return getAction("/lvxs/tourCommunity/list", params).then((res) => {
        if (res.success) {
          return res.result.records;
        }
      });
    },
    culChange() {
      // 国内旅行社
      this.typeChange("国内旅行社").then((res) => {
        this.cxwndata = res;
      });
      // 国际旅行社
      this.typeChange("国际旅行社").then((res) => {
        this.cxmrdata = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.strip {
  height: 52px;
  background-color: rgba($color: #6EB400, $alpha: 0.09);
  margin: 43px 18px 30px 18px;
  .car {
    width: 567px;
    height: 52;
    line-height: 52px;
    font-size: 22px;
    text-align: center;
    color: #000;
    background-color: #6EB40000;
  }
  .cars {
    width: 567px;
    height: 52;
    line-height: 52px;
    font-size: 22px;
    text-align: center;
    color: #000;
    background-color: #6EB40000;
    float: right;
    margin-top: -52px;
  }
  a :hover {
    color: #ffffff;
    background-color: #6EB400;
  }
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
::v-deep.router-link-active {
  color: #ffffff;
  background-color: #6EB400;
}
.navig-box {
  margin-bottom: 50px;
  padding: 0 18px 0 18px;
  .navig-list {
    list-style: none;
    min-height: 400px;
    .navig-item {
      border-bottom: 1px dashed #dbdbdb;
      padding: 25px 0;
      .imgleft {
        width: 100%;
        height: 184px;
      }
      .navig-info {
        padding: 10px 0 9px;
        .title {
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.5s;
          color: #000;
        }
        .desc {
          margin: 20px 0;
          height: 78px;
          font-size: 14px;
          line-height: 26px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-align: justify;
        }
        .time {
          color: #999999;
          font-size: 14px;
          .arrow {
            font-size: 20px;
            transition: all 0.5s;
          }
        }
      }
      &:hover {
        .title {
          color: #6EB400;
        }
        .time {
          .arrow {
            color: #6EB400;
          }
        }
      }
    }
  }
}
::v-deep.el-tabs__nav-scroll {
  margin: 0px 18px 0px 22px;
}
::v-deep.el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid #e4e7ed00;
}
::v-deep.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed00;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}
::v-deep.el-tabs__item.is-active {
  color: #000;
}
::v-deep.el-tabs__item:hover {
  color: #999999;
  cursor: pointer;
}
::v-deep.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #ffffff;
  background-color: #6EB400;
}
/deep/.el-tabs__item {
  width: 567px;
  height: 52px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  color: #000;
  background-color: #6EB40021;
}
</style>
